<template>
  <el-card
    class="price-method">
    <div slot="header" style="text-align:left;">
      <div class="price-methodology-name">{{ methodologyName }}</div>
      <div class="price-code">{{ priceCode? priceCode: '-' }}</div>
      <div class="country">{{ country? country : '-' }}</div>
      <div class="monthly-avg">Monthly Average: {{ monthlyAverage? parseFloat(monthlyAverage).toFixed(3): '-' }}</div>
    </div>
    <div>
      <el-row class="price-row" justify="center">
        <el-col :span="8" class="text-center"><strong>Low</strong></el-col>
        <el-col :span="8" class="text-center"><strong>High</strong></el-col>
        <el-col :span="8" class="text-center"><strong>Mid</strong></el-col>
      </el-row>
      <el-row v-for="(item, index) in priceMethodologiesList" :key="index" class="price-row">
        <el-col :span="8" class="text-center">
          <el-input-number type='number' v-model.float="item.lowPrice" :controls="false" :disabled="checkIsReadOnly(item.methodologyDate)"  :precision='2' :step="0.01" class="number-input"
                    size="mini" style="width: 90px;"
                    @change="() => isChanged(item, 'lowPrice')"
                    />
        </el-col>
        <el-col :span="8" class="text-center">
          <el-input-number type='number' v-model.float="item.highPrice"  :controls="false" :disabled="checkIsReadOnly(item.methodologyDate)"  :precision='2' :step="0.01"  class="number-input" size="mini"
                    style="width: 90px;" @change="() => isChanged(item, 'highPrice')"/>
        </el-col>
        <el-col :span="8" class="text-center">
          <el-input-number v-model.float="item.midPrice"  :controls="false" :precision='2' :step="0.01"  class="number-input" disabled size="mini"
                    style="width: 90px;"/>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import moment from 'moment'
import { formatDate } from '@/utils/dateTime.js'

export default {
  name: 'priceMethodology',
  data () {
    return {
      priceMethodlist: [],
      priceMethodologiesList: []
    }
  },
  props: {
    methodologyName: {
      type: String,
      default: 'Price Methodology1'
    },
    priceCode: {
      type: String,
      default: 'Price Code'
    },
    country: {
      type: String,
      default: 'Singapore'
    },
    methodologyPrices: {
      type: Array,
      default: () => {
        return []
      }
    },
    companyId: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    bunkerwireType: {
      type: String,
      default: ''
    },
    month: {
      type: String,
      default: moment().locale('en').format('MMMM')
    },
    year: {
      type: String,
      default: moment().locale('en').format('yyyy')
    },
    holiday: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted () {
    this.momenttime
    this.getAllPriceByMethodologyId()
  },
  methods: {
    checkIsReadOnly (date) {
      const tempDate = moment(date)
      const isHoliday = this.holiday.find(x => formatDate(x.calendarDate) === tempDate.format('DD-MM-yyyy'))
      return tempDate.day() === 0 || tempDate.day() === 6 || isHoliday?.fullDay
    },
    async save () {
      const filteredList = this.priceMethodologiesList.filter(x => x.isEdited)
      if (filteredList.length > 0) {
        const promises = await filteredList.map(item => {
          let pItem
          if (item.id) {
            pItem = this.createOrUpdateMethodPrice(item)
          } else {
            if (item.lowPrice || item.highPrice) {
              pItem = this.createOrUpdateMethodPrice(item)
            }
          }
          return pItem
        })
        await Promise.allSettled(promises).then(res => {
          if (res.length > 0) {
            this.$emit('setIsLoading', false)
            this.getAllPriceByMethodologyId()
          }
        })
      } else {
        this.$emit('setIsLoading', false)
      }
    },
    getAllPriceByMethodologyId () {
      const month = moment(`${this.month} ${this.year}`).format('M')
      const url = `${this.$apis.methodologies}/${this.id}/methodologyPrices/years/${this.year}/monthes/${month}`
      this.$request.get({ url }).then(res => {
        if (res.code === 1000) {
          if (res.data === null) {
            this.priceMethodlist = []
          } else {
            this.priceMethodlist = this.processDate(res.data)
          }
        }
      })
    },
    isChanged (item, type) {
      item.isEdited = true
      item[type] = parseFloat(item[type]).toFixed(2)
    },
    async createOrUpdateMethodPrice (methodPrice) {
      const priceItem = this.$_.cloneDeep(methodPrice)
      priceItem.highPrice = (priceItem.highPrice === 'NaN') ? 0 : priceItem.highPrice
      priceItem.lowPrice = (priceItem.lowPrice === 'NaN') ? 0 : priceItem.lowPrice
      const item = { ...priceItem, methodologyDate: new Date(priceItem.methodologyDate) }
      if (item.id) {
        const tempUrl = `${this.$apis.methodPrice}`
        return await this.$request.put({
          url: tempUrl,
          data: item
        })
      } else {
        item.midPrice = (parseInt(item.lowPrice, 10) + parseInt(item.highPrice, 10)) / 2
        // item.midPrice = (parseFloat(item.lowPrice) + parseFloat(item.highPrice)) / 2
        return await this.$request.post({
          url: this.$apis.methodPrice,
          data: item
        })
      }
    },

    processDate (priceMethod) {
      Array.from(priceMethod).forEach(p => {
        p.createdAt = new Date(p.createdAt)
        p.updatedAt = new Date(p.updatedAt)
        p.methodologyDate = new Date(p.methodologyDate)
      })
      return priceMethod
    }
  },
  computed: {
    methodologiesPrices () {
      const newList = []
      this.momenttime.map(item => {
        const theItem = this.priceMethodlist.find(price => formatDate(price.methodologyDate) === item.normalDate.format('DD-MM-yyyy'))
        const newItem = {
          ...item,
          methodologyDate: item.normalDate._d,
          methodology: {
            id: this.id,
            companyId: this.companyId,
            bunkerwireType: this.bunkerwireType,
            methodologyName: this.methodologyName,
            index: this.index
          },
          ...theItem,
          isEdited: false
        }
        newList.push(newItem)
        return item
      })

      this.priceMethodologiesList = newList
      return newList
    },
    momenttime () {
      const numberOfDay = moment(`${this.month} ${this.year}`).locale('en').daysInMonth()
      const dayInMonth = []
      for (let i = 1; i <= numberOfDay; i++) {
        const theDate = moment(`${this.month} ${this.year}`).locale('en').startOf('month').date(i)

        const item = {
          date: theDate.format('DD/MM/yyyy ddd'),
          normalDate: theDate,
          isWeekend: theDate.day() === 0 || theDate.day() === 6
        }
        dayInMonth.push(item)
      }
      return dayInMonth
    },
    monthlyAverage () {
      let monthMidTotal = 0
      const numberOfDay = this.priceMethodlist.length

      this.priceMethodologiesList.map(x => {
        if (x.midPrice) {
          monthMidTotal += x.midPrice
        }
        return x
      })

      if (monthMidTotal === 0 || Number.isNaN(monthMidTotal)) {
        return 0
      }

      const monthAvg = (monthMidTotal / numberOfDay).toFixed(2)

      return monthAvg
    }
  },
  watch: {
    priceMethodologiesList (newState, oldState) {

    },
    priceMethodlist (newState, oldState) {
      this.methodologiesPrices
    },
    month (newState, oldState) {
      this.momenttime
      this.getAllPriceByMethodologyId()
    },
    year (newState, oldState) {
      this.momenttime
      this.getAllPriceByMethodologyId()
    },
    id () {
      this.getAllPriceByMethodologyId()
    }
  }
}
</script>

<style scoped>
.price-method {
  display: inline-block;
  border-top: solid #0b3b5d 5px;
  height: 100%;
  margin: 0 0 0 15px;
  border-radius: 10px;
}

.text-center {
  text-align: center;
}

.el-row.price-row {
  margin: 0 0 5px 0;
}

/* .price-row:nth-child(even) .el-input.el-input--mini /deep/ input {
  background-color: #F6FBFF !important;
} */

.number-input /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input input[type=number] {
  -moz-appearance: textfield;
}

.price-methodology-name {
  font-size: 21px;
  font-weight: 500;
}

.price-code {
  font-size: 17px;
  font-weight: 500;
  margin-top: 5px;
}

.country {
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
}

.monthly-avg {
  margin-top: 12px;
}
</style>
